<template>
  <div class="bread-crumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <slot></slot>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bread-switch">
      我是开发者
      <el-switch
        v-model="switchValue"
        active-color="#13ce66"
        inactive-color="#ccc"
        @change="changeSwitch"
      >
      </el-switch>
    </div>
  </div>
</template>

<script>
export default {
  // 便于调试工具调试
  name: 'MyBreadcrumb',
  data() {
    return {
      switchValue: false
    }
  },
  methods: {
    changeSwitch(val) {
      this.$EventBus.$emit('switchChanged', val)
    }
  }
}
</script>

<style lang="less">
@import '~@/styles/index.less';
.bread-crumb {
  display: flex;
  justify-content: space-between;
  .bread-switch {
    color: rgb(160, 168, 176);
  }
}
</style>
